import Vue from 'vue'
import router from './router'
import App from './App.vue'
import '@/fonts/iconfont.css'
import axios from 'axios';
import less from 'less';
import VueLazyload from 'vue-lazyload';
import 'animate.css'

Vue.use(less)

Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
Vue.prototype.$global = {isBack:false,isPc:true,company_info:'',lang:1}

//懒加载
Vue.use(VueLazyload, {
  preLoad: 1,             // 预加载高度比例，默认 1.3
  // error: errorimage,      // 加载失败时图像的src
  // loading: loadimage,     // 正在加载时图像的src
  attempt: 1,             // 尝试次数
});

//www.wuweb.com
axios.defaults.baseURL = 'http://api.jxintian.cn/index.php'
axios.defaults.timeout = 12000
Vue.prototype.$http = axios
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
