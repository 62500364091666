import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
     {
          path: '/',
          name: 'home',
          component: HomeView
     },
     {
          path: '/info',
          name: 'info',
          component: () => import('../views/info.vue')
     },
     {
          path: '/homelist',
          name: 'homelist',
          component: () => import('../views/homelist.vue')
     },
     {
          path: '/about',
          name: 'about',
          component: () => import('../views/about.vue')
     },
     {
          path: '/bannerlist',
          name: 'bannerlist',
          component: () => import('../views/bannerlist.vue')
     },
     {
          path: '/bannerinfo',
          name: 'bannerinfo',
          component: () => import('../views/bannerinfo.vue')
     },
     {
          path: '/productlist',
          name: 'productlist',
          component: () => import('../views/productlist.vue')
     },
     {
          path: '/productinfo',
          name: 'productinfo',
          component: () => import('../views/productinfo.vue')
     }
]


const router = new VueRouter({
     // mode: 'history',
     scrollBehavior() {
          return {x: 0, y: 0};
     },
     routes
   })
   
   export default router